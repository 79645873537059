import React, { Component } from 'react'
import './PlanoContas.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import { Grid, Button } from '@material-ui/core'
import FormPlanoContas from '../../components/forms/FormPlanoContas'
import SaveIcon from '@material-ui/icons/Save'
import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios'

const initalState = {
  planoContas: {
    plancontas_descricao: "",
    plancontas_codigo: "",
    plancontas_nivel_superior_id: "",
    plancontas_unin_id: "",
    plancontas_ativo: true,
    parametros_financeiro: {
      paramfin_id: "",
      paramfin_aluno: "I",
      paramfin_assessor: "I",
      paramfin_funcionario: "I",
      paramfin_professor: "I",
      paramfin_caixa: "I",
      paramfin_parcelamento: "",
      paramfin_duplo: "",
      paramfin_recorrente: "",
      paramfin_relatorio: "",
      paramfin_material_id: "",
      paramfin_agrupar: "",
      paramfin_recepcao: "",
    }
  },
  planosContas: {
    list: []
  },
  produtos: {
    list: []
  },
  produto: {
    prod_descricao: "",
    prod_id: ""
  },
  modalErro: false,
  informarParametros: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  update: false
}

export default class CadastroPlanoContas extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentDidMount() {
    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'plano-contas')[0]

    this.setState({
      permissoes
    })

    if (params.planoContasId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    }

    this.setState({
      planoContas: {
        plancontas_descricao: "",
        plancontas_codigo: "",
        plancontas_nivel_superior_id: "",
        plancontas_unin_id: "",
        plancontas_ativo: true,
        parametros_financeiro: {
          paramfin_id: "",
          paramfin_aluno: "I",
          paramfin_assessor: "I",
          paramfin_funcionario: "I",
          paramfin_professor: "I",
          paramfin_caixa: "I",
          paramfin_parcelamento: "",
          paramfin_duplo: "",
          paramfin_recorrente: "",
          paramfin_relatorio: "",
          paramfin_material_id: "",
          paramfin_agrupar: "",
          paramfin_recepcao: "",
        }
      },
      update: false
    })

    try {
      let filtro = {
        descricao: "",
        subGrupoProduto: "",
        grupoProduto: "",
        visibilidade: "",
        ativo: true
      }

      const { data: produtos } = await axios.post(`${process.env.REACT_APP_API_URL}/produtos/list`, {...filtro}, this.getToken())

      this.setState({
        produtos: {
          list: produtos.filter(produto => produto.subgrupoproduto.grupoproduto.grupprod_descricao === "Material Didático" && produto.prod_ativo === true).map(param => {
            return {
              prod_descricao: param.prod_descricao,
              prod_id: param.prod_id
            }
          })
        },
        loading: false
      })

    } catch (error) {
      console.log(error)
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar os plano de contas, tente mais tarde"
      })

      this.backPage()
    }

    try {
      const { data: planosContas } = await axios.get(`${process.env.REACT_APP_API_URL}/planoContas`, this.getToken())
      const { planoContas } = this.state
      const planos = planosContas.filter(param => param.plancontas_nivel_superior_id === null)

      const codigo = planos.length + 1

      if (codigo < 10) {
        planoContas.plancontas_codigo = `0${codigo}`
      } else {
        planoContas.plancontas_codigo = `${codigo}`
      }

      this.setState({
        planoContas,
        planosContas: {
          list: planosContas
        },
      })
    } catch (error) {
      console.log(error)
    }

    if (params.planoContasId) {
      try {
        const { data: planoContas } = await axios.get(`${process.env.REACT_APP_API_URL}/planoContas/${params.planoContasId}`, this.getToken())

        let parametros_financeiro = this.state.planoContas.parametros_financeiro

        if (planoContas.parametros) {
          this.setState({
            informarParametros: true
          })

          parametros_financeiro = {
            paramfin_id: planoContas.parametros.paramfin_id,
            paramfin_aluno: planoContas.parametros.paramfin_aluno,
            paramfin_assessor: planoContas.parametros.paramfin_assessor,
            paramfin_funcionario: planoContas.parametros.paramfin_funcionario,
            paramfin_professor: planoContas.parametros.paramfin_professor,
            paramfin_caixa: planoContas.parametros.paramfin_caixa,
            paramfin_parcelamento: planoContas.parametros.paramfin_parcelamento,
            paramfin_duplo: planoContas.parametros.paramfin_duplo,
            paramfin_recorrente: planoContas.parametros.paramfin_recorrente,
            paramfin_relatorio: planoContas.parametros.paramfin_relatorio,
            paramfin_material_id: planoContas.parametros.paramfin_material_id,
            paramfin_agrupar: planoContas.parametros.paramfin_agrupar,
            paramfin_recepcao: planoContas.parametros.paramfin_recepcao,
          }

          if (parametros_financeiro.paramfin_material_id !== "" && parametros_financeiro.paramfin_material_id) {
            this.setState({
              produto: {
                prod_descricao: planoContas.parametros.material.prod_descricao,
                prod_id: planoContas.parametros.material.prod_id
              },
            })
          }
        }

        this.setState({
          planoContas: {
            plancontas_descricao: planoContas.plancontas_descricao,
            plancontas_codigo: planoContas.plancontas_codigo,
            plancontas_nivel_superior_id: planoContas.plancontas_nivel_superior_id ? planoContas.plancontas_nivel_superior_id : "",
            plancontas_unin_id: planoContas.plancontas_unin_id,
            plancontas_ativo: planoContas.plancontas_ativo,
            parametros_financeiro: parametros_financeiro
          },
          update: true
        })

      } catch (error) {
        console.log(error)
        // this.handleOpenDialog({
        //   titulo: "Opps!",
        //   descricao: "Não foi possível buscar o plano de contas, tente mais tarde"
        // })

        // this.backPage()
      }
    }

  }

  updateField(event) {
    const planoContas = { ...this.state.planoContas }
    const planosContas = this.state.planosContas

    if (event.target.name === 'plancontas_nivel_superior_id') {
      const plano = planosContas.list.filter(param => param.plancontas_id === event.target.value)
      const planoInferior = planosContas.list.filter(param => param.plancontas_nivel_superior_id === event.target.value)

      const codigo = planoInferior.length + 1

      if (codigo < 10) {
        planoContas.plancontas_codigo = `${plano[0].plancontas_codigo}.0${codigo}`
      } else {
        planoContas.plancontas_codigo = `${plano[0].plancontas_codigo}.${codigo}`
      }

      planoContas.plancontas_nivel_superior_id = event.target.value

      this.setState({ planoContas })
    } else {
      planoContas[event.target.name] = event.target.value
      this.setState({ planoContas })
    }
  }

  updateFieldParametro(event) {
    const planoContas = { ...this.state.planoContas }

    planoContas.parametros_financeiro[event.target.name] = event.target.value
    this.setState({ planoContas })
  }

  updateFieldAtivo() {
    const planoContas = { ...this.state.planoContas }
    planoContas.plancontas_ativo = !planoContas.plancontas_ativo
    this.setState({ planoContas })
  }

  updateFieldTemParametros() {
    this.setState({
      informarParametros: !this.state.informarParametros
    })
  }

  updateFieldProduto(event, value) {
    if (!value) return false
    const planoContas = { ...this.state.planoContas }

    planoContas.parametros_financeiro.paramfin_material_id = value.prod_id
    planoContas.parametros_financeiro.paramfin_agrupar = 'Material ' + value.prod_descricao

    this.setState({
      produto: value
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/plano_contas");
    }, timeout)
    this.setState({
      produto: initalState.produto
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  validateForm() {
    const { planoContas } = this.state

    if (!planoContas.plancontas_descricao) return false
    if (!planoContas.plancontas_codigo) return false

    if (this.state.informarParametros) {
      if (!planoContas.parametros_financeiro.paramfin_parcelamento) return false
      if (planoContas.parametros_financeiro.paramfin_duplo === '') return false
      if (planoContas.parametros_financeiro.paramfin_recorrente === '') return false
      if (planoContas.parametros_financeiro.paramfin_relatorio === '') return false
      if (planoContas.parametros_financeiro.paramfin_recepcao === '') return false
    }

    return true
  }

  async salvar() {
    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const planoContas = this.state.planoContas

    try {

      const { match: { params } } = this.props;

      const method = params.planoContasId ? 'put' : 'post'
      const url = params.planoContasId ? `/planoContas/${params.planoContasId}` : `/planoContas`

      if (planoContas.parametros_financeiro.paramfin_id === "") {
        delete planoContas.parametros_financeiro.paramfin_id
      }

      if (planoContas.plancontas_nivel_superior_id === "") {
        delete planoContas.plancontas_nivel_superior_id
      }
      if (planoContas.plancontas_unin_id === "") {
        delete planoContas.plancontas_unin_id
      }

      if (this.state.informarParametros) {
        if (planoContas.parametros_financeiro.paramfin_material_id === "" || !planoContas.parametros_financeiro.paramfin_material_id) {
          delete planoContas.parametros_financeiro.paramfin_material_id
          delete planoContas.parametros_financeiro.paramfin_agrupar
        }
      } else {
        delete planoContas.parametros_financeiro
      }

      await axios[method](`${process.env.REACT_APP_API_URL}${url}`, planoContas, this.getToken())

      this.setState({
        planoContas: initalState.planoContas
      })

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()

    } catch ({ response }) {
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  render() {
    const { planoContas, produto } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h2 className="titulo">Cadastro de Plano de Contas</h2>
                </Grid>
              </Grid>
              <FormPlanoContas
                updateField={e => this.updateField(e)}
                updateFieldAtivo={e => this.updateFieldAtivo(e)}
                updateFieldProduto={(e, value) => this.updateFieldProduto(e, value)}
                updateFieldParametro={e => this.updateFieldParametro(e)}
                updateFieldTemParametros={e => this.updateFieldTemParametros(e)}
                dados={planoContas}
                planos={this.state.planosContas.list}
                produto={produto}
                produtos={this.state.produtos.list}
                update={this.state.update}
                informarParametros={this.state.informarParametros}
              />

              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <br />
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}