import React, { Component } from 'react'
import './Book.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button } from '@material-ui/core'

import FormBook from '../../components/forms/FormBook'
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios'
import ModalConfirmacao from './../../components/modals/Confirmacao'
import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';

const initalState = {
  book: {
    _id: "",
    bok_nome: "",
    bok_descricao: "",
    bok_produto_id: "",
    bok_start: false,
    bok_ordem: 1,
    bok_ativo: true
  },
  produto: {
    prod_id: "",
    prod_descricao: ""
  },
  books: {
    list: []
  },
  produtos: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  aplicar_pontos: false,
  modalConfirmacaoOpen: false,
  modalConfirmacao: {
    mensagem: '',
  },
}

export default class CadastroCargo extends Component {

  state = { ...initalState }
  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'books')[0]

    this.setState({
      permissoes
    })

    if (params.bookId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    }

    if (params.bookId) {
      try {
        const { data: book } = await axios.get(`${process.env.REACT_APP_API_URL}/book/${params.bookId}`, this.getToken())

        this.setState({
          book: {
            _id: book.bok_id,
            bok_nome: book.bok_nome,
            bok_descricao: book.bok_descricao,
            bok_produto_id: book.bok_produto_id,
            bok_start: book.bok_start,
            bok_ordem: book.bok_ordem,
            bok_ativo: book.bok_ativo
          },
          produto: {
            prod_id: book.bok_produto_id,
            prod_descricao: book.produto.prod_descricao
          }
        })

      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o book, tente mais tarde"
        })

        this.backPage()
      }
    }

    try {
      let filtro = {
        descricao: "",
        subGrupoProduto: "",
        grupoProduto: "",
        visibilidade: "",
        ativo: true
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/produtos/list`, {...filtro}, this.getToken())

      let produtosAtivos = []
      let produtos = []

      produtosAtivos = resp.data.filter(u => u.prod_ativo === true)

      produtosAtivos.forEach(produto => {
        produtos.push({
          prod_id: produto.prod_id,
          mid_caminho: produto.midia.mid_caminho,
          prod_descricao: produto.prod_descricao,
          prod_unidademedida: produto.prod_unidademedida,
          subgrupprod_descricao: produto.subgrupoproduto.subgrupprod_descricao,
          subgrupprod_id: produto.subgrupoproduto.subgrupprod_id,
          grupprod_descricao: produto.subgrupoproduto.grupoproduto.grupprod_descricao,
          prod_visibilidade: produto.prod_visibilidade,
          prod_ativo: produto.prod_ativo
        })
      })

      this.setState({
        produtos: {
          list: produtos
        },
        loading: false
      })

    } catch (error) {
      console.log(error);
    }
  }

  updateField(event) {
    const book = { ...this.state.book }
    book[event.target.name] = event.target.value
    this.setState({ book })
  }

  updateFieldAtivo(event) {
    const book = { ...this.state.book }
    book.bok_ativo = !book.bok_ativo
    this.setState({ book })
  }

  updateFieldStart(event) {
    const book = { ...this.state.book }
    book.bok_start = !book.bok_start
    this.setState({ book })
  }

  updateFieldProduto(event, value) {
    if (!value) return false

    const book = { ...this.state.book }
    book.bok_produto_id = value.prod_id
    this.setState({
      book,
      produto: {
        prod_id: value.prod_id,
        prod_descricao: value.prod_descricao
      },
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/book");
    }, timeout)
    this.setState({
      book: initalState.book,
      produto: initalState.produto
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  validateForm() {
    const { book } = this.state

    if (!book.bok_nome) return false
    if (!book.bok_produto_id) return false
    if (!book.bok_ordem) return false

    return true
  }

  openModalConfirmacao(){
    this.setState({ loadingSalvar: true })

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    // const { match: { params } } = this.props;

    // if (params.bookId) {
    //   this.setState({
    //     modalConfirmacaoOpen: true,
    //     modalConfirmacao: {
    //       mensagem: 'Deseja aplicar essa pontuação para todos os cursos que possuem esse book?',
    //     }
    //   })
    // }else{
    //   this.salvar(false)
    // }

    this.salvar(false)
   
  }

  async salvar(value) {
    const book = this.state.book
  
      const dados = {
        bok_nome: book.bok_nome,
        bok_descricao: book.bok_descricao,
        bok_produto_id: book.bok_produto_id,
        bok_start: book.bok_start,
        bok_ordem: book.bok_ordem,
        bok_ativo: book.bok_ativo,
        // aplicar_pontos: value
      }
  
      try {
        if (book._id !== "") {
          await axios.put(`${process.env.REACT_APP_API_URL}/book/${book._id}`, dados, this.getToken())
        } else {
          await axios.post(`${process.env.REACT_APP_API_URL}/book`, dados, this.getToken())
        }
  
        this.handleOpenDialog({
          titulo: 'Parabéns',
          descricao: 'Cadastro realizado com sucesso.'
        })

        this.setState({ loadingSalvar: false })

        this.backPage()
  
      } catch ({ response }) {
        if (response.status === 400 || response.status === 500) {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: response.data.message
          })
          return
        }

        this.setState({ loadingSalvar: false })
  
        this.handleOpenDialog({})
      }

  }

  handleCloseConfirmacao() {
    this.setState({
      modalConfirmacaoOpen: false
    })
  }

  render() {
    const { book } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h2 className="titulo">Cadastro de Book</h2>
                </Grid>
              </Grid>
              <FormBook 
                produtos={this.state.produtos.list} 
                produto={this.state.produto} 
                updateField={e => this.updateField(e)} 
                updateFieldAtivo={e => this.updateFieldAtivo(e)} 
                updateFieldProduto={(e, value) => this.updateFieldProduto(e, value)} 
                dados={book}
                updateFieldStart={e => this.updateFieldStart(e)}  />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.openModalConfirmacao(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          <ModalConfirmacao open={this.state.modalConfirmacaoOpen} dados={this.state.modalConfirmacao} handleClose={e => this.handleCloseConfirmacao(e)} confirmar={e => this.salvar(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}