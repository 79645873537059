import React, { Component } from 'react'
import './Relatorios.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import { saveAs } from 'file-saver';
import ModalErro from './../../components/modals/Erro'
import ModalSubRelatorio from './../../components/modals/QuantidadeAulas'
import ModalRelatorioAlunos from './../../components/modals/Relatorios'

const initalState = {
  filtro: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    data_inicio_vigencia: '2022-08-01',
    stcontmat_situacao: "",
    unin_id: "",
    mes: ""
  },
  subFiltro: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unin_id: ""
  },
  cabecalhoTabelaModal: [],
  acoesTabelaModal: [],
  relatoriosModal: {
    list: []
  },
  cabecalhoTabela: [],
  acoesTabela: [],
  relatorios: {
    list: []
  },
  subCabecalhoTabela: [],
  subAcoesTabela: [],
  subRelatorios: {
    list: []
  },
  subUrlView: '',
  relatorios_options: {
    list: [{
      perm: {
        perm_visualizar: false
      }
    }]
  },
  loading: false,
  urlView: '',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  schedules: {
    list: []
  },
  modalSubRelatorio: false,
  cabecalhoTabelaQtdAulas: [
    { id: 'ageaula_data_hora', numeric: false, disablePadding: true, label: 'Data' },
    { id: 'aula_nome', numeric: false, disablePadding: true, label: 'Aula' },
    { id: 'professor', numeric: false, disablePadding: false, label: 'Professor' },
    { id: 'qtd_alunos2', numeric: false, disablePadding: false, label: 'Qtd. Alunos' }
  ],
  acoesTabelaQtdAulas: [],
  filtroSub: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unin_id: ""
  },
  openModalRelatorioAlunos: false,
}

export default class Administrativo extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    this.setState({
      filtro: {
        relatorio: "",
        data_inicial: '',
        data_final: '',
        data_inicio_vigencia: '2022-08-01',
        stcontmat_situacao: "",
        unin_id: "",
        mes: ""
      },
    })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'rel-administrativo')

    this.setState({
      relatorios_options: {
        list: permissoes.map(value => {
          return {
            tela_descricao: value.tela.tela_descricao,
            tela_slug: value.tela.tela_slug,
            perm: {
              perm_visualizar: value.perm_visualizar
            }
          }
        })
      }
    })
  }

  updateFieldFiltro(event) {
    const filtro = this.state.filtro

    filtro[event.target.name] = event.target.value

    this.setState({ filtro })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  async filtrar() {
    const filtro = this.state.filtro

    this.setState({
      loading: true
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      if(filtro.relatorio === 'aniversariantes-periodo'){
        if (filtro.mes === '') {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: 'Por favor informar o mês de referência'
          })
          this.setState({
            loading: false
          })
          return
        }
      }

      if(filtro.relatorio !== 'aluno-sem-codigo' && filtro.relatorio !== 'aniversariantes-mes'){
        if (filtro.data_inicial === '') {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: 'Por favor informar a data inicial'
          })
          this.setState({
            loading: false
          })
          return
        }
  
        if (filtro.data_final === '') {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: 'Por favor informar a data final'
          })
          this.setState({
            loading: false
          })
          return
        }
      }

      if(filtro.relatorio === 'alunos-ativos-ead'){
        if (filtro.data_inicio_vigencia === '') {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: 'Por favor informar o inicio da vigência do contrato'
          })
          this.setState({
            loading: false
          })
          return
        }
      }

      filtro.considerarInativos = false

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio`, filtro, this.getToken())

      this.setState({
        cabecalhoTabela: data.cabecalhoTabela,
        acoesTabela: data.acoesTabela,
        relatorios: {
          list: data.list
        },
        urlView: data.urlView
      })

      this.setState({
        loading: false
      })

    } catch (error) {
      this.setState({
        loading: false
      })
      console.log(error)
    }
  }

  async imprimir(value) {
    const { filtro, filtroSub, subRelatorios, cabecalhoTabela } = this.state

    this.setState({
      loadingDownload: true
    })

    const ordenacao = JSON.parse(localStorage.getItem('ordenacao'))

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      let filtroImpressao = {}
      let indexCabecalho = -1

      if(ordenacao){
        indexCabecalho = cabecalhoTabela.findIndex(param => param.id === ordenacao.property)
      }

      if(indexCabecalho !== -1){
        if(ordenacao.isAsc){
          filtro.list = this.state.relatorios.list.sort((a, b) => (a[ordenacao.property] < b[ordenacao.property]) ? 1 : (b[ordenacao.property] < a[ordenacao.property]) ? -1 : 0)
        }else{
          filtro.list = this.state.relatorios.list.sort((a, b) => (a[ordenacao.property] > b[ordenacao.property]) ? 1 : (b[ordenacao.property] > a[ordenacao.property]) ? -1 : 0)
        }
      }else{
        filtro.list = this.state.relatorios.list
      }

      console.log('value: ', value)

      if(value === 'principal'){
        filtro.unidadesnegocio = dados.unidadesnegocio
        // filtro.list = relatorios.list

        filtroImpressao = { ...filtro}
      }else{
        filtroSub.unidadesnegocio = dados.unidadesnegocio
        filtroSub.list = subRelatorios.list

        filtroImpressao = {...filtroSub}
      }

      console.log('filtroImpressao: ', filtroImpressao)

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio/imprimir`, filtroImpressao, this.getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingDownload: false
      })

      if (filtro.relatorio === 'vencimento-contratos')
        saveAs(pdfBlob, `Vencimento-Contratos.pdf`);

      if (filtro.relatorio === 'alunos-ativos')
        saveAs(pdfBlob, `Alunos-Ativos-Unidade.pdf`);

      if (filtro.relatorio === 'aulas-ministradas')
        saveAs(pdfBlob, `Quantidade-Aulas.pdf`);

      if (filtro.relatorio === 'aluno-aulas-professor')
        saveAs(pdfBlob, `Aluno-Aula-Professor.pdf`);

      if (filtro.relatorio === 'contratos-periodo')
        saveAs(pdfBlob, 'Contratos-Periodo.pdf')

      if (filtro.relatorio === 'agenda-aula-periodo')
        saveAs(pdfBlob, 'Agenda-Aulas-Periodo.pdf')
      
      if (filtro.relatorio === 'aulas-abertas-periodo')
        saveAs(pdfBlob, 'Aulas-abertas-periodo.pdf')
    
      if (filtro.relatorio === 'aluno-sem-codigo')
        saveAs(pdfBlob, 'Alunos-Sem-Códigos.pdf')

      if (filtro.relatorio === 'termino-ausencia-temporaria')
        saveAs(pdfBlob, 'Término-Ausência-Temporária.pdf')
        
      if (filtro.relatorio === 'aniversariantes-mes')
        saveAs(pdfBlob, 'Aniversariantes-mes.pdf')

      if (filtro.relatorio === 'observacoes-data-alerta')
        saveAs(pdfBlob, 'Observacoes-Data-Alerta.pdf')

      if (filtro.relatorio === 'faltas-alunos-periodo')
        saveAs(pdfBlob, 'Faltas-Alunos-Periodo.pdf')

      if (filtro.relatorio === 'alunos-ativos-ead')
        saveAs(pdfBlob, 'Aluno-Ativos-EAD.pdf')

    } catch (error) {
      this.setState({
        loadingDownload: false
      })
      console.log(error)
    }
  }

  async viewRow(ids, acao, status) {
    try {
      console.log('acao: ', acao)

      if(acao !== 'aluno-aulas-professor'){
        let filtroAux = {
          relatorio: acao,
          ids: ids,
          status
        }
                
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio`, filtroAux, this.getToken())
  
        const filtroSub = this.state.filtroSub
  
        filtroSub.relatorio = acao
        filtroSub.list = data.list
    
        this.setState({
          cabecalhoTabelaModal: data.cabecalhoTabela,
          acoesTabelaModal: data.acoesTabela,
          relatoriosModal: {
            list: data.list
          },
          filtroSub,
          urlViewModal: data.urlView,
          openModalRelatorioAlunos: true,
          linhaSelecionada: status
        })
        
      }else{
        try {
          let {filtro, filtroSub} = this.state

          const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio/consultaAlunoAulas`, ids, this.getToken())
    
          const schedules = []
    
          data.forEach(agenda => {
            schedules.push({
              _id: agenda.ageaula_id,
              aula_nome: agenda.aula.aula_nome,
              professor: agenda.professor.funcionario.fisica.pesf_nome,
              ageaula_data_hora: agenda.ageaula_data_hora,
              qtd_alunos2: agenda.alunos.length,
              alunos: agenda.alunos
            })
          })
    
          filtroSub.list = schedules
          filtroSub.relatorio = acao
          
          this.setState({
            subCabecalhoTabela: this.state.cabecalhoTabelaQtdAulas,
            subAcoesTabela: this.state.acoesTabelaQtdAulas,
            subRelatorios: {
              list: schedules
            },
            subUrlView: data.urlView,
            modalSubRelatorio: true,
            filtroSub
          })

          this.setState({
            loading: false
          })
    
          this.setState({
            schedules: {
              list: schedules
            },
            modalSubRelatorio: true
          })
    
        } catch (error) {
          console.log(error)
          // this.handleOpenDialog({
          //   titulo: 'Ops...',
          //   descricao: response.data.message
          // })
          // return
        }
      }


    } catch (error) {
      console.log(error)
      // this.handleOpenDialog({
      //   titulo: 'Ops...',
      //   descricao: response.data.message
      // })
      // return
    }
  }

  handleCloseModalRelatorioAlunos(){
    this.setState({
      openModalRelatorioAlunos: false
    })
  }


  // async viewRow(row, acao) {
  //   const filtro = this.state.filtro

    
    
  //   if(acao !== 'aluno-aulas-professor'){
  //     try {

  //       const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio`, subFiltro, this.getToken())

  //       this.setState({
  //         subCabecalhoTabela: data.cabecalhoTabela,
  //         subAcoesTabela: data.acoesTabela,
  //         subRelatorios: {
  //           list: data.list
  //         },
  //         subUrlView: data.urlView,
  //         modalSubRelatorio: true,
  //         subFiltro
  //       })

  //       this.setState({
  //         loading: false
  //       })

  //     } catch (error) {
  //       this.setState({
  //         loading: false
  //       })
  //       console.log(error)
  //     }

  //   }else{
  //     try {
  
  //       const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio/consultaAlunoAulas`, row, this.getToken())
  
  //       const schedules = []
  
  //       data.forEach(agenda => {
  //         schedules.push({
  //           _id: agenda.ageaula_id,
  //           aula_nome: agenda.aula.aula_nome,
  //           professor: agenda.professor.funcionario.fisica.pesf_nome,
  //           ageaula_data_hora: agenda.ageaula_data_hora,
  //           qtd_alunos2: agenda.alunos.length,
  //           alunos: agenda.alunos
  //         })
  //       })
  
  //       filtro.list = schedules

  //       this.setState({
  //         subCabecalhoTabela: this.state.cabecalhoTabelaQtdAulas,
  //         subAcoesTabela: this.state.acoesTabelaQtdAulas,
  //         subRelatorios: {
  //           list: schedules
  //         },
  //         subUrlView: data.urlView,
  //         modalSubRelatorio: true,
  //         subFiltro
  //       })

  //       this.setState({
  //         loading: false
  //       })
  
  //       this.setState({
  //         schedules: {
  //           list: schedules
  //         },
  //         filtro,
  //         modalSubRelatorio: true
  //       })
  
  //     } catch ({response}) {
  //       // console.log(error)
  //       this.handleOpenDialog({
  //         titulo: 'Ops...',
  //         descricao: response.data.message
  //       })
  //       return
  //     }
  //   }

  // }

  handleCloseQuantidadeAulas() {
    this.setState({
      modalSubRelatorio: false
    })
  }

  render() {
    const { filtro, cabecalhoTabela, acoesTabela, relatorios, urlView, loadingDownload, relatorios_options } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Grid container spacing={1} direction="row" className="borderBottom">
            <Grid item md={11} xs={10}>
              <h1 className="titulo">Relatório Administrativo</h1>
            </Grid>
            {loadingDownload &&
              <Grid item md={1} xs={2}>
                <CircularProgress />
              </Grid>
            }
          </Grid>
          <Grid container spacing={1} direction="row" className="borderBottom mg_top_20">
            <Grid item md={4} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Relatório"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="relatorio"
                value={filtro.relatorio}
                onChangeCapture={(e) => this.updateFieldFiltro(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value="">--Escolha o Relatório--</option>
                {relatorios_options.list.sort((a, b) => (a.tela_descricao > b.tela_descricao) ? 1 : ((b.tela_descricao > a.tela_descricao) ? -1 : 0)).map(value => {
                  if (value.perm) {
                    if (value.perm.perm_visualizar) {
                      return (
                        <option value={value.tela_slug}>{value.tela_descricao}</option>
                      )
                    }
                  }
                  return false
                })}
              </TextField>
            </Grid>
            { filtro.relatorio === 'aniversariantes-mes' && 
              <React.Fragment>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Mês"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="mes"
                    value={filtro.mes}
                    onChangeCapture={(e) => this.updateFieldFiltro(e)}
                  >
                    <option value=""></option>  
                    <option value="1">Janeiro</option>  
                    <option value="2">Favereiro</option>  
                    <option value="3">Março</option>  
                    <option value="4">Abril</option>  
                    <option value="5">Maio</option>  
                    <option value="6">Junho</option>  
                    <option value="7">Julho</option>  
                    <option value="8">Agosto</option>  
                    <option value="9">Setembro</option>  
                    <option value="10">Outubro</option>  
                    <option value="11">Novembro</option>  
                    <option value="12">Dezembro</option>  
                  </TextField>
                </Grid>
              </React.Fragment>
            }
            { filtro.relatorio === 'alunos-ativos-ead' && 
              <React.Fragment>
               <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type="date" label="Data do Inicio da Vigência" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicio_vigencia} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
              </React.Fragment>
            }
            { filtro.relatorio !== 'aniversariantes-mes' && 
              <React.Fragment>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type="date" label="Data Incial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type="date" label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
              </React.Fragment>
            }
            { filtro.relatorio === 'aluno-sem-codigo' && 
            <Grid item md={2} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Situação do Aluno"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="stcontmat_situacao"
                value={filtro.stcontmat_situacao}
                onChangeCapture={(e) => this.updateFieldFiltro(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value=""></option>
                <option value="APROVADO">Aprovado</option>
                <option value="INATIVO">Inativo</option>
                <option value="TRANSFERIDO">Transferido</option>
                <option value="CANCELADO">Cancelado</option>
                <option value="ENCERRADO">Encerrado</option>
              </TextField>
            </Grid>
            }
            <Grid item md={2} xs={12} sm={3}>
              <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrar()} disabled={filtro.relatorio === '' ? true : false}>
                Filtrar
              </Button>
            </Grid>
            {relatorios.list.length > 0 &&
              <Grid item md={2} xs={12} sm={3}>
                <Button fullWidth variant="contained" color="secondary" onClick={() => this.imprimir('principal')} disabled={loadingDownload}>
                  Imprimir
                </Button>
              </Grid>
            }
          </Grid>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Relatório...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <React.Fragment>
              {cabecalhoTabela.length > 0 &&
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12}>
                    <Table
                      urlView={urlView}
                      headCell={cabecalhoTabela}
                      rows={relatorios.list}
                      acoes={acoesTabela}
                      viewRow={(e, acao) => this.viewRow(e, acao)} />
                  </Grid>
                </Grid>
              }
            </React.Fragment>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          <ModalSubRelatorio
            open={this.state.modalSubRelatorio}
            urlView={this.state.subUrlView}
            handleClose={e => this.handleCloseQuantidadeAulas(e)}
            list={this.state.subRelatorios.list}
            cabecalhoTabela={this.state.subCabecalhoTabela}
            acoesTabela={this.state.subAcoesTabela}
            imprimir={e => this.imprimir(e)}
            loadingDownload={loadingDownload}
          />
          <ModalRelatorioAlunos
            open={this.state.openModalRelatorioAlunos}
            handleClose={e => this.handleCloseModalRelatorioAlunos(e)}
            list={this.state.relatoriosModal.list}
            cabecalhoTabela={this.state.cabecalhoTabelaModal}
            acoesTabela={this.state.acoesTabelaModal}
            imprimir={e => this.imprimirSub(e)}
            loadingDownload={loadingDownload}
            urlView={this.state.urlViewModal}
            linhaSelecionada={this.state.linhaSelecionada}
          />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}