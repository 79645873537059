import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, Button, TextField } from '@material-ui/core'
import SignaturePad from "react-signature-canvas";
import { withStyles } from "@material-ui/core/styles";
import Color from 'color';

const ColorYellowButton = withStyles((theme) => ({
  root: {
    color: '#000',
    backgroundColor: Color('#FFFF00').alpha(0.9).string(),
    '&:hover': {
      backgroundColor: '#FFFF00',
    },
  },
}))(Button);

const ColorVioletButton = withStyles((theme) => ({
  root: {
    color: '#FFF',
    backgroundColor: Color('#8A2BE2').alpha(0.9).string(),
    '&:hover': {
      backgroundColor: '#8A2BE2',
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '90%',
    marginLeft: '5%',
    marginTop: '30px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
    listStyle: "none",
    maxHeight: "90%",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
}));

export default function SimpleModal(props) {
  const sigCanvas = useRef({})

  const classes = useStyles();

  const clear = () => sigCanvas.current.clear();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Assinar Contrato - {props.contrato.contm_codigo}</h5>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row">
            <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
              <p>Olá {props.contrato.resp_fin ? props.contrato.resp_fin.pesf_nome : props.aluno.pesf_nome}, segue a baixo {props.contrato.resp_fin ? ` o contrato do aluno ${props.aluno.pesf_nome}` : ` o seu contrato`} Cod.: {props.contrato.contm_codigo}, ao final do contrato realizar a sua assinatura.</p>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={1} xs={false} sm={1}></Grid>
            <Grid item md={10} xs={12} sm={10}>
              <div style={{border: '1px solid #000', padding: '20px'}} dangerouslySetInnerHTML={{__html: props.contrato.documento.contaluno_html}} />  
            </Grid>
            <Grid item md={1} xs={false} sm={1}></Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_20">
            <Grid item md={2} xs={12} sm={1}></Grid>
            <Grid item md={8} xs={12} sm={10} style={{textAlign: 'center'}}>
            {!props.contrato.resp_fin && 
              <p>Eu {props.aluno.pesf_nome}, portador do CPF {props.aluno.pesf_cpf} e do RG {props.aluno.pesf_rg}, li e concordo com os termos do contrato de num. {props.contrato.contm_codigo}</p>
            }

            {props.contrato.resp_fin && 
              <p>Eu {props.contrato.resp_fin.pesf_nome}, portador do CPF {props.contrato.resp_fin.pesf_cpf} e do RG {props.contrato.resp_fin.pesf_rg}, responsável pelo aluno {props.aluno.pesf_nome}, li e concordo com os termos do contrato de num. {props.contrato.contm_codigo}</p>
           }
            <p>Sua Assinatura:</p>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row">
            <Grid item md={2} xs={12} sm={1}></Grid>
            <Grid item md={8} xs={12} sm={10}>
              <SignaturePad
                ref={sigCanvas}
                canvasProps={{
                  className: "signatureCanvas"
                }}
              />
            </Grid>
            <Grid item md={2} xs={12} sm={1}></Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={2} xs={12} sm={4}></Grid>
            <Grid item md={4} xs={12} sm={4} >
              <TextField
                className="input" 
                label="Informar o Código Recebido por E-mail*" 
                variant="outlined" 
                size="small" 
                name="codigo" 
                value={props.codigo} 
                onChange={(e) => props.updateField(e)} 
                InputLabelProps={{ shrink: true }} 
                inputProps={{ maxLength: 6 }}
              />   
            </Grid>
            <Grid item md={4} xs={12} sm={4}>
              <ColorVioletButton style={{marginTop: 7}} fullWidth variant="contained" className="btn_salvar" size="small" onClick={e => props.baixarContrato()}>Baixar Contrato</ColorVioletButton>
            </Grid>
          </Grid>
          <br />
          <hr />
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={4} xs={12} sm={4}>
              <ColorYellowButton fullWidth variant="contained" className="btn_salvar" size="small" onClick={clear} disabled={props.travaBotao}>Limpar</ColorYellowButton>
            </Grid>
            <Grid item md={4} xs={12} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" onClick={e => props.confirmar(false, sigCanvas)} disabled={props.travaBotao}>Voltar</Button>
            </Grid>
            <Grid item md={4} xs={12} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => props.confirmar(true, sigCanvas)} disabled={props.travaBotao}>Assinar Contrato</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
