import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, Button } from '@material-ui/core'
import CurrencyInput from './../mask/CurrencyInput'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import real from '../../services/real'

function getModalStyle() {

  return {
  
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '96%',
    marginLeft: '2%',
    marginTop: '30px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
    listStyle: "none",
    maxHeight: "90%",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const {dados, config_aditivos, updateFieldAditivo, curso_aditivo, updateFieldCursoAditivo, 
		travarParcelas, updateFieldMaterial, materiais, formasPagamento, tabelaPrecoMaterial} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Vincular Aditivo</h5>
            </Grid>
          </Grid>
          <br />
          <Grid container direction="row" spacing={1}>
						<Grid item md={4} xs={12} sm={4}>
							<TextField
								id="standard-select-currency"
								select
								label="Selecionar Aditivo"
								variant="outlined"
								className="input"
								fullWidth
								size="small"
								SelectProps={{
									native: true,
								}}
								name="ccad_id"
								value={dados.ccad_id}
								onChangeCapture={(e) => updateFieldAditivo(e)}
								InputLabelProps={{ shrink: true }}
							>
								<option value={dados.ccad_id !== '' ? dados.ccad_id : ''}> {dados.ccad_id !== '' ? dados.ccad_descricao : 'Selecionar Aditivo'}  </option>
								{config_aditivos.map(config => {
									return (
										<option key={config.ccad_id} value={config.ccad_id}> {config.ccad_descricao} </option>
									)
								})}
							</TextField>
						</Grid>
						{dados.ccad_id !== '' &&
							<Grid item md={4} xs={12} sm={4}>
								<TextField
									id="standard-select-currency"
									select
									label="Selecionar Curso"
									variant="outlined"
									className="input"
									fullWidth
									size="small"
									SelectProps={{
										native: true,
									}}
									name="cursad_id"
									value={curso_aditivo.cursad_id}
									onChangeCapture={(e) => updateFieldCursoAditivo(e)}
									InputLabelProps={{ shrink: true }}
								>
									<option value={curso_aditivo.cursad_id !== '' ? curso_aditivo.cursad_id : ''}> {curso_aditivo.cursad_id !== '' ? `${curso_aditivo.curso_atual.curso_nome} >> ${curso_aditivo.curso_novo.curso_nome}` : 'Selecionar Curso'}  </option>
									{dados.cursos_aditivo.filter(param => param.cursad_id !== parseInt(curso_aditivo.cursad_id)).map(curso_adit => {
										return (
											<option key={curso_adit.cursad_id} value={curso_adit.cursad_id}> {`${curso_adit.curso_atual.curso_nome} >> ${curso_adit.curso_novo.curso_nome}`} </option>
										)
									})}
								</TextField>
							</Grid>
						}
					</Grid>

					{dados.ccad_id !== '' && 
						<React.Fragment>
							{curso_aditivo.cursad_id !== '' && 
								<React.Fragment>
									<br />
									<hr />
									<Grid container direction="row" spacing={1}>
										<Grid item md={12} xs={12} sm={12}>
											<h5>Material Didatico:</h5>
										</Grid>
									</Grid>
									<br />
									<Grid container direction="row" spacing={1}>
										<Grid item md={2} xs={12} sm={6}>
											<TextField
												id="standard-select-currency"
												select
												variant="outlined"
												className="input"
												fullWidth
												label="Tipo de Material*"
												size="small"
												SelectProps={{
													native: true,
												}}
												name="matcont_tipo"
												value={curso_aditivo.material.matcont_tipo}
												onChangeCapture={(e) => updateFieldMaterial(e)}
												InputLabelProps={{ shrink: true }}
												disabled={dados.contm_curso_id === '' ? true: false}
											>
												<option value="">--Escolha--</option>
												<option value="Físico">Físico</option>
												<option value="Digital">Digital</option>
											</TextField>
										</Grid>
										<Grid item md={3} xs={12} sm={6}>
											<TextField
												id="standard-select-currency"
												select
												variant="outlined"
												className="input"
												fullWidth
												label="Material Didático Adquirido*"
												size="small"
												SelectProps={{
													native: true,
												}}
												name="matcont_prod_id"
												value={curso_aditivo.material.matcont_prod_id}
												onChangeCapture={(e) => updateFieldMaterial(e)}
												InputLabelProps={{ shrink: true }}
											>
												<option value={curso_aditivo.material.matcont_prod_id !== '' ? curso_aditivo.material.matcont_prod_id : ''}>{curso_aditivo.material.matcont_prod_id !== '' ? curso_aditivo.material.matcont_prod_descricao : '--Escolha--'}</option>
												{materiais.map((value, key) => {
													return (
														<option key={key} value={value._id}>{value.descricao}</option>
													)
												})}
											</TextField>
										</Grid>
										<Grid item md={2} xs={12} sm={6}>
											<TextField fullWidth className="input" label="Valor do Material Didático*" variant="outlined"
												size="small" name="matcont_valor" value={curso_aditivo.material.matcont_valor}
												onChange={(e) => updateFieldMaterial(e)} InputLabelProps={{ shrink: true }}
												InputProps={{ inputComponent: CurrencyInput }} />
										</Grid>
										<Grid item md={2} xs={12} sm={4}>
											<TextField fullWidth className="input" label="N. de Parcelas Material" variant="outlined"
												size="small" type="number" name="matcont_num_parcelas" value={curso_aditivo.material.matcont_num_parcelas}
												InputLabelProps={{ shrink: true }} onChange={(e) => updateFieldMaterial(e)} />
										</Grid>
										<Grid item md={3} xs={12} sm={4}>
											<TextField 
												fullWidth 
												type="number" 
												className="input" 
												label="Dia vencimento parcela Material" 
												variant="outlined"
												size="small" 
												name="matcont_dia_vencimento" 
												value={curso_aditivo.material.matcont_dia_vencimento}
												InputLabelProps={{ shrink: true }} 
												onChange={(e) => updateFieldMaterial(e)} />
										</Grid>
									</Grid>
									<Grid container direction="row" spacing={1} className="mg_top_10">
									<Grid item md={3} xs={12} sm={6}>
											<TextField
												id="standard-select-currency"
												select
												variant="outlined"
												className="input"
												fullWidth
												label="Forma de pagamento de Material*"
												size="small"
												SelectProps={{
													native: true,
												}}
												name="matcont_formpag_id"
												value={curso_aditivo.material.matcont_formpag_id}
												onChangeCapture={(e) => updateFieldMaterial(e)}
												InputLabelProps={{ shrink: true }}
											>
												<option value="">--Escolha--</option>
												{formasPagamento.map(forma => {
													return (
														<option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
													)
												})}
											</TextField>
										</Grid>
										<Grid item md={3} xs={12} sm={4}>
											<TextField
												id="standard-select-currency"
												select
												variant="outlined"
												className="input"
												fullWidth
												label="Conta Financeira"
												size="small"
												SelectProps={{
													native: true,
												}}
												name="contfin_id"
												value={curso_aditivo.material.contfin_id}
												onChangeCapture={(e) => updateFieldMaterial(e)}
												InputLabelProps={{ shrink: true }}
												disabeld={curso_aditivo.material.matcont_formpag_id === ''}
											>
												<option value="">--Escolha--</option>
												{props.contasFinanceira.filter(param => param.contfin_tipo_conta === (curso_aditivo.material.matcont_formpag_id === 1 ? 'COFRE': 'BANCO')).map(conta => {
													return (
														<option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_descricao}</option>
													)
												})}
											</TextField>
										</Grid>
									</Grid>


									{tabelaPrecoMaterial && curso_aditivo.material.matcont_prod_id !== '' &&
										<Grid item md={12} xs={12} sm={12}>
											<h6><b>Tabela de preço do Material</b></h6>
											<p style={{ margin: 0 }}>Nome: {tabelaPrecoMaterial.tabprec_descricao}</p>
											<p style={{ margin: 0 }}>Valor Mínimo: R$ {real(tabelaPrecoMaterial.preco_produto.precprod_valor_minimo)} </p>
											<p style={{ margin: 0 }}>Valor Máximo: R$ {real(tabelaPrecoMaterial.preco_produto.precprod_valor_maximo)} </p>
										</Grid>
									}

									{!tabelaPrecoMaterial && curso_aditivo.material.matcont_prod_id &&
										<Grid item md={12} xs={12} sm={12}>
											<h6 style={{ color: '#ff5a5a' }}>Produto sem Tabela de Preço</h6>
										</Grid>
									}
								</React.Fragment>
							}
						</React.Fragment>
					}

					

					{dados.ccad_id !== '' && 
						<React.Fragment>
							{curso_aditivo.cursad_id !== '' &&
								<React.Fragment>
									{dados.ccad_gera_fin &&
										<React.Fragment >
											<br />
											<hr />
											<Grid container direction="row" spacing={1}>
												<Grid item md={12} xs={12} sm={12}>
													<h5>Lançamento Financeiro:</h5>
												</Grid>
											</Grid>
											<Grid container direction="row" spacing={1} className="mg_top_20">
												<Grid item md={2} xs={12} sm={6}>
													<TextField className="input" label="Tipo da Conta" variant="outlined" value={props.conta.contpr_tipo} InputLabelProps={{ shrink: true }} disabled/>
												</Grid>
												<Grid item md={4} xs={12} sm={6}>
													<TextField className="input" label="Plano de Contas" variant="outlined" value={`${props.conta.plancontas_codigo} - ${props.conta.plancontas_descricao}`} InputLabelProps={{ shrink: true }} disabled/>
												</Grid>
												<Grid item md={4} xs={12} sm={6}>
													<TextField className="input" label="Descrição*" variant="outlined" name="contpr_descricao" value={props.conta.contpr_descricao} InputLabelProps={{ shrink: true }} disabled/>
												</Grid>
												<Grid item md={2} xs={12} sm={6}>
													<TextField className="input" label="Valor*" variant="outlined" name="contpr_valortotal" value={props.conta.contpr_valortotal} onChange={(e) => props.updateFieldConta(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
												</Grid>
											</Grid>
											<Grid container direction="row" spacing={1} className="mg_top_10">
												<Grid item md={12} xs={12} sm={12}>
													<div className='scroll'>
														<table className="lista sempadding borderTable">
															<thead>
																<tr className="titulos acompanha">
																	<th>Parcelas</th>
																	<th>Valor*</th>
																	<th>Vencimento*</th>
																	<th>Forma de Pagamento*</th>
																	<th>Conta Financeira</th>
																	<th>Documento</th>
																</tr>
															</thead>
															<tbody>
																{props.conta.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
																	return (
																		<tr key={parcela.index}>
																			<td>
																				<TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.index} InputLabelProps={{ shrink: true }} disabled />
																				<span className="separacaoParcelas">/</span>
																				<TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={props.conta.qtd_parcelas} onChange={(e) => props.updateFieldConta(e)} onBlur={(e) => props.mudarParcelas(e)} InputLabelProps={{ shrink: true }} />
																			</td>
																			<td>
																				<TextField className="inputValor" variant="outlined" size="small" name="parcont_valorparcela" value={parcela.parcont_valorparcela} onBlur={(e) => props.recalcularParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={travarParcelas}/>
																			</td>
																			<td>
																				<TextField type="date" className="inputVencimento" variant="outlined" size="small" name="parcont_datavencimento" value={parcela.parcont_datavencimento} onChange={(e) => props.updateFieldParcela(e, parcela.index)} onBlur={(e) => props.verificaDataParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
																			</td>
																			<td>
																				<TextField
																					id="standard-select-currency"
																					select
																					variant="outlined"
																					className="inputFormPagamento"
																					fullWidth
																					size="small"
																					SelectProps={{
																						native: true,
																					}}
																					name="parcont_formapagto_id"
																					value={parcela.parcont_formapagto_id}
																					onChangeCapture={(e) => props.updateFieldParcela(e, parcela.index)}
																					InputLabelProps={{ shrink: true }}
																				>
																					<option value="">--Escolha--</option>
																					{props.formasPagamento.map(forma => {
																						return (
																							<option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
																						)
																					})}
																				</TextField>
																			</td>
																			<td>
																				<TextField
																					id="standard-select-currency"
																					select
																					variant="outlined"
																					className="inputConta"
																					fullWidth
																					size="small"
																					SelectProps={{
																						native: true,
																					}}
																					name="contfin_id"
																					value={parcela.contfin_id}
																					onChangeCapture={(e) => props.updateFieldParcela(e, parcela.index)}
																					InputLabelProps={{ shrink: true }}
																					disabled={parcela.parcont_formapagto_id === ''}
																				>
																					<option value="">--Escolha--</option>
																					{props.contasFinanceira.filter(param => param.contfin_tipo_conta === (parcela.parcont_formapagto_id === 1 ? 'COFRE': 'BANCO')).map(conta => {
																						return (
																							<option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_descricao}</option>
																						)
																					})}
																				</TextField>
																			</td>
																			<td>
																				<TextField className="inputDocumento" variant="outlined" size="small" name="parcont_documento" value={parcela.parcont_documento} onChange={(e) => props.updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
																			</td>
																		</tr>
																	)
																})}
															</tbody>
														</table>
													</div>
												</Grid>
											</Grid>
											<br />
											<hr />
											<br />
										</React.Fragment>
									}
									<Grid container direction="row" spacing={1}>
										<Grid item md={12} xs={12} sm={12}>
											<p>Cofirmar aditivo?</p>
											<p className='avisoConciliacao'><i>Esta operação não poderá ser desfeita.</i></p>
										</Grid>
									</Grid>
									<Grid container direction="row" spacing={1}>
										<Grid item md={6} xs={6} sm={6}>
											<Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={props.loadingSalvar}>Sim</Button>
										</Grid>
										<Grid item md={6} xs={6} sm={6}>
											<Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)}disabled={props.loadingSalvar}>Não</Button>
										</Grid>
									</Grid>
								</React.Fragment>
							}
						</React.Fragment>
					}
        </div>
      </Modal>
    </div>
  );
}
