import React, { Component } from 'react'
import './VendaProduto.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import { Grid, Button } from '@material-ui/core'
import FormVenda from '../../components/forms/FormVenda'
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import moment from 'moment';
import Alert from './../../components/templates/Alert'
import real from './../../services/real'
import moeda from './../../services/moeda'
import { withStyles } from "@material-ui/core/styles";
import Color from 'color';
import ModalExibeBoleto from './../../components/modals/ExibeBoleto'

const ColorDarkGreenButton = withStyles((theme) => ({
  root: {
    color: '#FFF',
    backgroundColor: Color('#006400').alpha(0.9).string(),
    '&:hover': {
      backgroundColor: '#006400',
    },
  },
}))(Button);

const initalState = {
  venda: {
    pedcomp_id: '',
    pedcomp_numero: '',
    pedcomp_data_compra: '',
    pedcomp_unidade_id: '',
    pedcomp_observacao: '',
    pedcomp_situacao: '',
    valor_total: real(0),
    itens: []
  },
  config_venda: {
    cv_id: '',
    cv_lancar_receita: 'Sim',
    cv_lancar_despesa: 'Sim',
    cv_enviar_not_email: 'Não',
    cv_enviar_not_whats: 'Não',
    cv_email: '',
    cv_telefone: '55',
    cv_tabela_preco_id: '',
    formas_pagamento: []
  },
  cabecalhoTabela: [
    { id: 'prod_descricao', numeric: false, disablePadding: true, label: 'Produto' },
    { id: 'valor', numeric: false, disablePadding: false, label: 'Valor Unit.' },
    { id: 'desconto', numeric: false, disablePadding: false, label: 'Desc./Acresm. Unit' },
    { id: 'qtd', numeric: false, disablePadding: false, label: 'Quantidade' },
    { id: 'valor_total', numeric: false, disablePadding: false, label: 'Valor Total' },
  ],
  acoesTabela: [],
  produtos: {
    list: []
  },
  unidades: {
    list: []
  },
  boletoSelecionado:{
    tipo_transaction: 'BOLETO',
    bank_slip:{
      url_slip_pdf: ''
    }
  },
  loadingSalvar: false,
  update: false,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  tabelaPreco: {
    tabprec_id: "",
    tabprec_pessoa_id: "",
    tabprec_descricao: "",
    tabprec_data_inicio: "",
    tabprec_data_fim: "",
    itens: []
  },
  produto_selecionado: {
    ipcomp_id: '',
    prod_id: '',
    precprod_id: '',
    prod_descricao: '',
    precprod_produto_id: '',
    precprod_valor_maximo: '',
    precprod_valor_minimo: '',
    precprod_ativo: '',
    valor: real(0),
    qtd: 0,
    logo: '',
    desconto: real(0),
    observacao: '',
    valor_total: real(0),
  },
  conta: {
    contpr_id: '',
    contpr_valortotal: real(0),
    contpr_numparcela: 1,
    qtd_parcelas: 1,
    parcelas: [
      {
        parcont_id: '',
        parcont_baixado: false,
        parcont_datavencimento: moment().format('YYYY-MM-DD'),
        parcont_datavencimento_orig: moment().format('YYYY-MM-DD'),
        parcont_valorparcela: "",
        parcont_formapagto_id: "",
        parcont_documento: "",
        parcont_num: 1,
        parcela_editada: false,
        contfin_id: '',
        formpag_slug: '',
        index: 1
      }
    ]
  },
  formasPagamento: {
    list: []
  },
  contasFinanceira: {
    list: []
  },
  loading: true
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

export default class CadastroDepartamento extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentWillMount() {
    this.setState({
      venda: {
        pedcomp_id: '',
        pedcomp_numero: '',
        pedcomp_data_compra: '',
        pedcomp_unidade_id: '',
        pedcomp_observacao: '',
        pedcomp_situacao: '',
        valor_total: real(0),
        itens: []
      },
      config_venda: {
        cv_id: '',
        cv_lancar_receita: 'Sim',
        cv_lancar_despesa: 'Sim',
        cv_enviar_not_email: 'Não',
        cv_enviar_not_whats: 'Não',
        cv_email: '',
        cv_telefone: '55',
        cv_tabela_preco_id: '',
        formas_pagamento: []
      },
      produto_selecionado: {
        ipcomp_id: '',
        prod_id: '',
        precprod_id: '',
        prod_descricao: '',
        precprod_produto_id: '',
        precprod_valor_maximo: '',
        precprod_valor_minimo: '',
        precprod_ativo: '',
        valor: real(0),
        qtd: 0,
        logo: '',
        desconto: real(0),
        observacao: '',
        valor_total: real(0),
      }
    })

    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'venda-prod-central-unidade')[0]

    this.setState({
      permissoes
    })

    if (params.vendaId) {
      if (!permissoes.perm_alterar) {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: "Você não tem permissão para acessa essa tela!"
          }
        })

        this.backPage()
        return
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: "Você não tem permissão para acessa essa tela!"
          }
        })

        this.backPage()
        return
      }
    }

    let acoesTabela = []

    if ((permissoes.perm_inserir || permissoes.perm_alterar) && !params.vendaId) {
      acoesTabela = ['editar', 'removeTabela']
    }

    try {
      const { data: unidades } = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list`, {}, this.getToken())
      const { data: configVenda } = await axios.get(`${process.env.REACT_APP_API_URL}/configuracaoVenda`, this.getToken())

      this.setState({
        unidades: {
          list: unidades.filter(param => param.unin_status).map(unidade => {
            return {
              unin_id: unidade.unin_id,
              unin_descricao: unidade.unin_descricao,
              unin_numero: unidade.unin_numero,
            }
          })
        }
      })

      let itens = []

      if(configVenda){

        const { data: tabelaPreco } = await axios.get(`${process.env.REACT_APP_API_URL}/tabelaPreco/${configVenda.cv_tabela_preco_id}`, this.getToken())
        
        for (let i = 0; i < tabelaPreco.itens.length; i++) {
          itens.push({
            ipcomp_id: '',
            prod_id: tabelaPreco.itens[i].produto.prod_id,
            precprod_id: tabelaPreco.itens[i].precprod_id,
            prod_descricao: tabelaPreco.itens[i].produto.prod_descricao,
            precprod_produto_id: tabelaPreco.itens[i].precprod_produto_id,
            precprod_valor_maximo: tabelaPreco.itens[i].precprod_valor_maximo,
            precprod_valor_minimo: tabelaPreco.itens[i].precprod_valor_minimo,
            precprod_ativo: tabelaPreco.itens[i].precprod_ativo,
            logo: tabelaPreco.itens[i].produto.midia ? tabelaPreco.itens[i].produto.midia.mid_caminho : null,
            valor: real(0),
            qtd: 0,
            desconto: real(0),
            observacao: '',
            valor_total: real(0),
            index: ''
          })
        }

        const aux = {
          tabprec_id: tabelaPreco.tabprec_id,
          tabprec_pessoa_id: tabelaPreco.tabprec_pessoa_id,
          tabprec_descricao: tabelaPreco.tabprec_descricao,
          tabprec_data_inicio: moment(tabelaPreco.tabprec_data_inicio).format('YYYY-MM-DD'),
          tabprec_data_fim: moment(tabelaPreco.tabprec_data_fim).format('YYYY-MM-DD'),
          itens
        }

        this.setState({
          config_venda: {
            cv_id: configVenda.cv_id,
            cv_lancar_receita: configVenda.cv_lancar_receita ? 'Sim' : 'Não',
            cv_lancar_despesa: configVenda.cv_lancar_despesa ? 'Sim' : 'Não',
            cv_enviar_not_email: configVenda.cv_enviar_not_email ? 'Sim' : 'Não',
            cv_enviar_not_whats: configVenda.cv_enviar_not_whats ? 'Sim' : 'Não',
            cv_email: configVenda.cv_email || '',
            cv_telefone: configVenda.cv_telefone || '',
            cv_tabela_preco_id: configVenda.cv_tabela_preco_id,
            formas_pagamento: configVenda.formas_pagamento.map((forma, key) => {
              return {
                fpv_id: forma.fpv_id,
                fpv_formapagto_id: forma.fpv_formapagto_id,
                forma_descricao: forma.forma_pagamento.formpag_descricao,
                fpv_contfin_id: forma.fpv_contfin_id,
                contafin_descricao: forma.conta_financeira.contfin_descricao,
                habilita: forma.conta_financeira.contfin_tipo_conta,
                index: key
              }
            })
          },
          tabelaPreco: aux
        })
      }else{
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: "Configuração de Venda não cadastrada!"
          }
        })

        this.backPage()
        return
      }

      const { data: formasPagamento } = await axios.get(`${process.env.REACT_APP_API_URL}/formapagto`, this.getToken())

      this.setState({
        formasPagamento: {
          list: formasPagamento.filter(param => param.formpag_ativo === true)
        }
      })

      const { data: contasFinanceira } = await axios.get(`${process.env.REACT_APP_API_URL}/contaFinanceira/listByUnidade/1`, this.getToken())

      this.setState({
        contasFinanceira: {
          list: contasFinanceira
        }
      })

      if (params.vendaId) {
        const { data: venda } = await axios.get(`${process.env.REACT_APP_API_URL}/pedidoCompra/${params.vendaId}`, this.getToken())

        if(venda.pedcomp_situacao === "AGUARDANDO_PAGAMENTO" ){
          acoesTabela = ['editar', 'removeTabela']
        }

        this.setState({
          venda: {
            pedcomp_id: venda.pedcomp_id,
            pedcomp_numero: venda.pedcomp_numero,
            pedcomp_data_compra: venda.pedcomp_data_compra,
            pedcomp_unidade_id: venda.pedcomp_unidade_id,
            pedcomp_observacao: venda.pedcomp_observacao,
            pedcomp_situacao: venda.pedcomp_situacao,
            valor_total: real(somarValores(venda.itens_pedido.map(value => { return (value.ipcomp_valor + value.ipcomp_desconto) * value.ipcomp_quantidade}))),
            itens: venda.itens_pedido.map((value, key) => { 
              let preco = itens.filter(param => param.prod_id === value.ipcomp_produto_id)[0]
              return {
                ipcomp_id: value.ipcomp_id,
                prod_id: value.ipcomp_produto_id,
                precprod_id: '',
                prod_descricao: value.produto.prod_descricao,
                precprod_produto_id: value.ipcomp_produto_id,
                precprod_valor_maximo: preco ? preco.precprod_valor_maximo : 0,
                precprod_valor_minimo: preco ? preco.precprod_valor_minimo : 0,
                precprod_ativo: '',
                valor: real(value.ipcomp_valor),
                qtd: value.ipcomp_quantidade,
                logo: '',
                desconto: real(value.ipcomp_desconto),
                observacao: value.ipcomp_observacao,
                valor_total: real((value.ipcomp_valor + value.ipcomp_desconto)*value.ipcomp_quantidade),
                index: key
              }
            })
          },
          conta: {
            contpr_id: venda.conta_receita.contpr_id,
            contpr_valortotal: real(venda.conta_receita.contpr_valortotal),
            contpr_numparcela: venda.conta_receita.contpr_numparcela,
            qtd_parcelas: venda.conta_receita.contpr_numparcela,
            parcelas: venda.conta_receita.parcelas.map((parcela) => {return {
              parcont_id: parcela.parcont_id,
              parcont_baixado: parcela.parcont_baixado,
              parcont_datavencimento: parcela.parcont_datavencimento,
              parcont_datavencimento_orig: parcela.parcont_datavencimento_orginal,
              parcont_valorparcela: real(parcela.parcont_valorparcela),
              parcont_formapagto_id: parcela.valores_pago[0].valpag_formapagto_id,
              parcont_documento: parcela.parcont_documento,
              parcont_situacao: parcela.parcont_situacao,
              parcont_num: parcela.parcont_num,
              parcela_editada: false,
              contfin_id: parcela.valores_pago[0].valpag_contfin_id,
              formpag_slug: parcela.valores_pago[0].forma_pagto.formpag_slug,
              index: parcela.parcont_num
            }})
          }
        })
      }

    } catch ({response}) {
      // console.log(error)
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }

    this.setState({
      loading: false,
      acoesTabela
    })

  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  updateField(event) {
    const {venda} = this.state
    venda[event.target.name] = event.target.value
    this.setState({ venda })
  }

  updateFieldProduto(event) {
    let {produto_selecionado, tabelaPreco} = this.state

    let name = event.target.name
    let value = event.target.value

    if(name === 'prod_id'){
      if(value !== ''){
        produto_selecionado = tabelaPreco.itens.filter(param => param.prod_id === parseInt(value))[0]
  
        produto_selecionado.valor = real(produto_selecionado.precprod_valor_maximo)
      }else{
        produto_selecionado = {
          prod_id: '',
          precprod_id: '',
          prod_descricao: '',
          precprod_produto_id: '',
          precprod_valor_maximo: '',
          precprod_valor_minimo: '',
          precprod_ativo: '',
          valor: real(0),
          qtd: 0,
          logo: '',
          desconto: real(0),
          observacao: '',
          valor_total: real(0),
          ipcomp_id: ''
        }
      }
    }else if(name === 'valor'){
      produto_selecionado[name] = value
    }else{
      produto_selecionado[name] = value
    }
  
    this.setState({ produto_selecionado })
  }

  addItem() {
    let {produto_selecionado, venda, conta} = this.state

    if (produto_selecionado.prod_id === '') {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message:  "Por favor informar o produto!"
        }
      })
      return
    }

    if (produto_selecionado.valor === '') {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message:  "Por favor informar o valor unit. do produto!"
        }
      })
      return
    }

    if(parseFloat(produto_selecionado.valor.replaceAll('.', '').replaceAll(',', '.')) === 0){
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message:  "Por favor informar o valor unit. do produto!"
        }
      })
      return
    }
  
    if (produto_selecionado.qtd === '' || parseInt(produto_selecionado.qtd) === 0) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message:  "Por favor informar a quantidade do produto!"
        }
      })
      return
    }

    let valor_produto = (parseFloat(produto_selecionado.valor.replaceAll('.', '').replaceAll(',', '.')) + parseFloat(produto_selecionado.desconto.replaceAll('.', '').replaceAll(',', '.')))

    if(valor_produto < produto_selecionado.precprod_valor_minimo){
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message:  "O valor do produto é inferior ao valor mínimo da tabela!"
        }
      })
      return
    }

    if(valor_produto > produto_selecionado.precprod_valor_maximo){
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message:  "O valor do produto é supeior ao valor máximo da tabela!"
        }
      })
      return
    }

    produto_selecionado.valor_total = real((parseFloat(produto_selecionado.valor.replaceAll('.', '').replaceAll(',', '.')) + parseFloat(produto_selecionado.desconto.replaceAll('.', '').replaceAll(',', '.'))) * parseInt(produto_selecionado.qtd))

    if(produto_selecionado.index === ''){
      produto_selecionado.index = venda.itens.length
      venda.itens.push({...produto_selecionado})
    }else{
      venda.itens[produto_selecionado.index] = {...produto_selecionado}
    }

    venda.valor_total = real(somarValores(venda.itens.map(value => {return parseFloat(value.valor_total.replaceAll('.', '').replaceAll(',', '.'))})))

    this.setState({
      venda,
      produto_selecionado: {
        prod_id: '',
        precprod_id: '',
        prod_descricao: '',
        precprod_produto_id: '',
        precprod_valor_maximo: '',
        precprod_valor_minimo: '',
        precprod_ativo: '',
        valor: real(0),
        qtd: 0,
        logo: '',
        desconto: real(0),
        observacao: '',
        valor_total: real(0),
        ipcomp_id: ''
      },
      conta: {
        contpr_id: conta.contpr_id,
        contpr_valortotal: venda.valor_total,
        contpr_numparcela: 1,
        qtd_parcelas: 1,
        parcelas: [
          {
            parcont_baixado: false,
            parcont_datavencimento: moment().format('YYYY-MM-DD'),
            parcont_valorparcela: venda.valor_total,
            parcont_formapagto_id: "",
            parcont_documento: "",
            parcont_num: "",
            parcela_editada: false,
            contfin_id: '',
            index: 1
          }
        ]
      }
    })
  }

  removerItem(index) {
    const {venda} = this.state

    let itens = venda.itens

    itens.splice(index, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      aux.push({
        ...itens[i],
        index: i
      })
    }

    itens = aux

    venda.itens = itens

    this.setState({
      venda
    })
  }

  editarItem(index) {
    let {venda} = this.state

    this.setState({
      venda,
      produto_selecionado: {...venda.itens[index]}
    })
  }

  backPage(timeout = 1000) {
    const self = this
   
    setTimeout(() => {
      self.props.history.push("/venda_prod_central_unidade");
    }, timeout)
  }

  updateFieldParcela(event, index) {
    const conta = { ...this.state.conta }

    let value = event.target.value
    let name = event.target.name

    if(name === 'parcont_datavencimento'){
      conta.parcelas[index - 1][name] = value
    }else{
      conta.parcelas[index - 1][name] = value
    }
    
    this.setState({ conta })
  }

  updateFieldConta(event) {
    const conta = { ...this.state.conta }

    conta[event.target.name] = event.target.value

    this.setState({ conta })
  }

  mudarParcelas(event) {
    const conta = { ...this.state.conta }

    let qtdParcelasInfo = 1

    if(parseInt(event.target.value) > 0){
      qtdParcelasInfo = parseInt(event.target.value)
    }

    let array = []

    const valorTotal = parseFloat(conta.contpr_valortotal.replace("R$ ", "").replace(".", "").replace(",", "."))
    let valorDividido = valorTotal / qtdParcelasInfo
    valorDividido = parseFloat(valorDividido.toFixed(2))

    let sobra = valorTotal - (valorDividido * qtdParcelasInfo)

    sobra = parseFloat(sobra.toFixed(2))

    const dataHoje = new Date()

    for (let i = 0; i < qtdParcelasInfo; i++) {
      let mesAcrecentado = new Date();
      mesAcrecentado.setMonth(dataHoje.getMonth() + (i));

      array.push({
        parcont_baixado: false,
        parcont_datavencimento: moment(mesAcrecentado).format('YYYY-MM-DD'),
        parcont_valorparcela: real(valorDividido),
        parcont_formapagto_id: "",
        parcont_documento: "",
        parcont_num: "",
        parcela_editada: false,
        index: i + 1
      })
    }

    if (sobra !== 0) {
      array = this.ajustarArray(qtdParcelasInfo, array, valorDividido, sobra)
    }

    conta.contpr_numparcela = qtdParcelasInfo
    conta.qtd_parcelas = qtdParcelasInfo
    conta.parcelas = array
    this.setState({ conta })
  }

  ajustarArray(qtdParcela, array, valorDividido, sobra) {
    const qtdSobra = parseInt(sobra.toString().replace('-', '').replace('0.0', ''))

    const arrayFalse = array.filter(param => param.parcela_editada === false)
    const arrayTrue = array.filter(param => param.parcela_editada === true)

    for (let i = 0; i < qtdSobra; i++) {
      arrayFalse[arrayFalse.length - (i + 1)].parcont_valorparcela = moeda((valorDividido + ((sobra) / qtdSobra)).toFixed(2))
    }

    for (let i = 0; i < arrayTrue.length; i++) {
      arrayFalse.push(arrayTrue[i])
    }

    return arrayFalse
  }

  verificaDataParcela(event, index){
    const {conta, planoContas} = this.state

    const data_hoje = new Date(moment(new Date()).format('YYYY-MM-DD'))
    const data_vencimento = new Date(event.target.value)

    if (data_vencimento.getTime() < data_hoje.getTime()) {
      conta.parcelas[index - 1].parcont_datavencimento = conta.parcelas[index - 1].parcont_datavencimento_orig
    }else{
      conta.parcelas[index - 1].parcont_datavencimento_orig = event.target.value
    }
    
    for (let i = index; i < conta.parcelas.length; i++) {
      let count = (i - index ) + 1

      if(planoContas.parametros.paramfin_parcelamento === 'Mensal'){
        let novaData = moment(event.target.value).add(count, 'months').format('YYYY-MM-DD')

        conta.parcelas[i].parcont_datavencimento = novaData
        conta.parcelas[i].parcont_datavencimento_orig = novaData
      }else{
        let qtdDias = 30 *  count
        let novaData = moment(event.target.value).add(qtdDias, 'days').format('YYYY-MM-DD')
        let diaSemana = moment(novaData).format('dddd')
        
        if(diaSemana === 'sábado'){
          novaData = moment(novaData).add(2, 'days').format('YYYY-MM-DD')
        }else if(diaSemana === 'domingo'){
          novaData = moment(novaData).add(1, 'days').format('YYYY-MM-DD')
        }

        conta.parcelas[i].parcont_datavencimento = novaData
        conta.parcelas[i].parcont_datavencimento_orig = novaData
      }
    }

    this.setState({ conta })
  }

  recalcularParcela(event, index) {
    const conta = { ...this.state.conta }

    const parcelaEditada = conta.parcelas[index - 1]

    if (parcelaEditada.parcont_valorparcela !== event.target.value) {

      const valorTotal = parseFloat(conta.contpr_valortotal.replace("R$ ", "").replace(".", "").replace(",", "."))

      let qtdParcelasEditado = this.state.qtdParcelasEditado

      if (!parcelaEditada.parcela_editada) {
        conta.parcelas[index - 1].parcela_editada = true
        qtdParcelasEditado = qtdParcelasEditado + 1

        this.setState({
          qtdParcelasEditado
        })
      }

      conta.parcelas[index - 1][event.target.name] = event.target.value

      const parcelasEditada = conta.parcelas.filter(param => param.parcela_editada)
      const somaParcelasEditada = somarValores(parcelasEditada.map(parcela => { return parseFloat(parcela.parcont_valorparcela.replace("R$ ", "").replace(".", "").replace(",", ".")) }))
      const diferenca = valorTotal - somaParcelasEditada
      const qtdParcelasNaoEditada = conta.qtd_parcelas - qtdParcelasEditado
      let valorDividido = parseFloat(diferenca / qtdParcelasNaoEditada).toFixed(2)
      valorDividido = parseFloat(valorDividido)


      let sobra = diferenca - (valorDividido * qtdParcelasNaoEditada)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        if (!conta.parcelas[i].parcela_editada) {
          conta.parcelas[i].parcont_valorparcela = moeda(valorDividido)
        }
      }

      if (sobra !== 0) {
        conta.parcelas = this.ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }

      this.setState({ conta })
    }
  }

  async salvar(baixado) {
    let {venda, conta} = this.state
   
    if(venda.pedcomp_unidade_id === ''){
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message:  "Por favor informar a unidade!"
        }
      })
      return
    }

    if(venda.itens.length === 0){
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message:  "Por favor informar ao menos um produto!"
        }
      })
      return
    }

    for (let i = 0; i < conta.parcelas.length; i++) {
      if (!conta.parcelas[i].parcont_datavencimento || conta.parcelas[i].parcont_datavencimento === '') {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message:  `Por favor informar a data de vencimento da parcela ${i+1}!`
          }
        })
        return
      }
      if (!conta.parcelas[i].parcont_valorparcela || conta.parcelas[i].parcont_valorparcela === '') {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message:  `Por favor informar o valor da parcela ${i+1}!`
          }
        })
        return
      }
      if (!conta.parcelas[i].parcont_formapagto_id || conta.parcelas[i].parcont_formapagto_id === '') {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message:  `Por favor informar a forma de pagamento da parcela ${i+1}!`
          }
        })
        return
      }
      if (!conta.parcelas[i].contfin_id || conta.parcelas[i].contfin_id === '') {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message:  `Por favor informar a conta financeira da parcela ${i+1}!`
          }
        })
        return
      }
    }

    try {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'info',
          message:  `Salvando venda...`
        }
      })

      let dados = {
        pedcomp_observacao: venda.pedcomp_observacao,
        pedcomp_unidade_id: parseInt(venda.pedcomp_unidade_id),
        produtos_pedidos: venda.itens.map(item => {
          return {
            ipcomp_id: item.ipcomp_id,
            ipcomp_valor: parseFloat(item.valor.replaceAll('.', '').replaceAll(',', '.')),
            ipcomp_desconto: parseFloat(item.desconto.replaceAll('.', '').replaceAll(',', '.')),
            ipcomp_observacao: item.observacao,
            ipcomp_quantidade: parseInt(item.qtd),
            ipcomp_produto_id: parseInt(item.precprod_produto_id)
          }
        }),
        conta: {
          baixado,
          contpr_id: conta.contpr_id || null,
          contpr_valortotal: parseFloat(conta.contpr_valortotal.replaceAll('.', '').replaceAll(',', '.')),
          contpr_numparcela: parseInt(conta.contpr_numparcela),
          parcelas: conta.parcelas.map(parcela => {
            return {
              parcont_id: parcela.parcont_id || null,
              parcont_baixado: baixado,
              parcont_datavencimento: parcela.parcont_datavencimento,
              parcont_valorparcela: parseFloat(parcela.parcont_valorparcela.replaceAll('.', '').replaceAll(',', '.')),
              parcont_formapagto_id: parseInt(parcela.parcont_formapagto_id),
              parcont_documento: parcela.parcont_documento || '',
              parcont_num: parseInt(parcela.index),
              parcont_contfin_id: parseInt(parcela.contfin_id)
            }
          })
        }
      }

      if(venda.pedcomp_id){
        await axios.put(`${process.env.REACT_APP_API_URL}/pedidoCompra/${venda.pedcomp_id}`, {...dados}, this.getToken())
      }else{
        await axios.post(`${process.env.REACT_APP_API_URL}/pedidoCompra`, {...dados}, this.getToken())
      }

      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'success',
          message:  `Venda Lançada!`
        }
      })
      this.backPage()

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  handleModalExibeBoleto(){
    this.setState({openModalExibeBoleto: false})
  }

  async confirmarPagamento(){
    let {venda, conta} = this.state

    for (let i = 0; i < conta.parcelas.length; i++) {
      if (!conta.parcelas[i].parcont_datavencimento || conta.parcelas[i].parcont_datavencimento === '') {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message:  `Por favor informar a data de vencimento da parcela ${i+1}!`
          }
        })
        return
      }
      if (!conta.parcelas[i].parcont_valorparcela || conta.parcelas[i].parcont_valorparcela === '') {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message:  `Por favor informar o valor da parcela ${i+1}!`
          }
        })
        return
      }
      if (!conta.parcelas[i].parcont_formapagto_id || conta.parcelas[i].parcont_formapagto_id === '') {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message:  `Por favor informar a forma de pagamento da parcela ${i+1}!`
          }
        })
        return
      }
      if (!conta.parcelas[i].contfin_id || conta.parcelas[i].contfin_id === '') {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message:  `Por favor informar a conta financeira da parcela ${i+1}!`
          }
        })
        return
      }
    }

    try {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'info',
          message:  `Baixando venda...`
        }
      })

      let dados = {
        pedcomp_id: venda.pedcomp_id,
        conta: {
          contpr_id: conta.contpr_id,
          contpr_valortotal: parseFloat(conta.contpr_valortotal.replaceAll('.', '').replaceAll(',', '.')),
          contpr_numparcela: parseInt(conta.contpr_numparcela),
          parcelas: conta.parcelas.map(parcela => {
            return {
              parcont_id: parcela.parcont_id || null,
              parcont_baixado: parcela.parcont_baixado,
              parcont_datavencimento: parcela.parcont_datavencimento,
              parcont_valorparcela: parseFloat(parcela.parcont_valorparcela.replaceAll('.', '').replaceAll(',', '.')),
              parcont_formapagto_id: parseInt(parcela.parcont_formapagto_id),
              parcont_documento: parcela.parcont_documento,
              parcont_num: parseInt(parcela.index),
              parcont_contfin_id: parseInt(parcela.contfin_id)
            }
          })
        }
      }

      await axios.put(`${process.env.REACT_APP_API_URL}/pedidoCompra/baixar`, {...dados}, this.getToken())
      
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'success',
          message:  `Venda Baixada!`
        }
      })
      this.backPage()

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  async imprimirBoleto(parcela){
    if(parcela.formpag_slug === 'pix'){
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/pix/atualizaPix`, {parcela_id: parcela.parcont_id}, this.getToken())

      this.setState({
        boletoSelecionado: {
          tipo_transaction: 'PIX',
          bank_slip:{
            url_slip_pdf: data.pix_code.qrcode_image_url,
            boleto_digitable_line: data.pix_code.emv
          }
        }
      })

      this.setState({
        openModalExibeBoleto: true
      })
    }else{
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/boleto/atualizaBoleto`, {parcela_id: parcela.parcont_id}, this.getToken())

      this.setState({
        boletoSelecionado: {
          tipo_transaction: 'PIX',
          bank_slip:{
            url_slip_pdf: data.pix_code.qrcode_image_url,
            boleto_digitable_line: data.pix_code.emv
          }
        }
      })

      this.setState({
        openModalExibeBoleto: true
      })
    }
  }

  async cancelarVenda(){
    let {venda} = this.state

    try {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'info',
          message:  `Cancelando venda...`
        }
      })

      await axios.put(`${process.env.REACT_APP_API_URL}/pedidoCompra/cancelar/${venda.pedcomp_id}`, {}, this.getToken())
      
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'success',
          message:  `Venda Cancelada!`
        }
      })
      this.backPage()

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  copyLink(link){
    navigator.clipboard.writeText(link);

    this.handleOpenDialog({
      titulo: 'Texto copiado',
      descricao: 'Texto copiado'
    })
  
    return
    
  }

  render() {
    const { venda, unidades, tabelaPreco, produto_selecionado } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history} id='view'>
        {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Venda...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <React.Fragment>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={11}>
                  <h2 className="titulo">Venda de Produtos</h2>
                </Grid>
                <Grid item md={1}>
                  {this.state.loadingSalvar &&
                    <div>
                      <CircularProgress />
                    </div>
                  }
                </Grid>
              </Grid>
              <FormVenda
                updateField={e => this.updateField(e)}
                dados={venda}
                unidades={unidades.list}
                produtos={tabelaPreco.itens}
                produto_selecionado={produto_selecionado}
                updateFieldProduto={e => this.updateFieldProduto(e)}
                addItem={e => this.addItem(e)}
                editarItem={e => this.editarItem(e)}
                removerItem={e => this.removerItem(e)}
                cabecalhoTabela={this.state.cabecalhoTabela}
                acoesTabela={this.state.acoesTabela}
                conta={this.state.conta}
                updateFieldParcela={(e, index) => this.updateFieldParcela(e, index)}
                mudarParcelas={(e) => this.mudarParcelas(e)}
                recalcularParcela={(e, index) => this.recalcularParcela(e, index)}
                verificaDataParcela={(e, index) => this.verificaDataParcela(e, index)}
                formasPagamento={this.state.formasPagamento.list}
                contasFinanceira={this.state.contasFinanceira.list}
                updateFieldConta={(e) => this.updateFieldConta(e)}
                imprimirBoleto={(e)=> this.imprimirBoleto(e)}
              />
              <Grid container direction="row" spacing={1} alignItems="flex-end" className="mg_top_10">
                <Grid item md={6}></Grid>
                {venda.pedcomp_situacao === 'FINALIZADO' &&
                  <Grid item md={4}></Grid>
                }
                {(venda.pedcomp_situacao !== '' && venda.pedcomp_situacao !== 'CANCELADO') &&
                  <Grid item md={2}>
                    <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" onClick={e => this.cancelarVenda(e)}>Cancelar Venda</Button>
                  </Grid>
                }
                {venda.pedcomp_situacao === '' &&
                  <React.Fragment>
                    <Grid item md={2}></Grid>
                    <Grid item md={2}>
                      <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => this.salvar(false)}>Salvar</Button>
                    </Grid>
                    <Grid item md={2}>
                      <ColorDarkGreenButton fullWidth variant="contained" className="btn_salvar" size="small" onClick={e => this.salvar(true)}>Salvar e Baixar</ColorDarkGreenButton>
                    </Grid>
                  </React.Fragment>
                }
                {venda.pedcomp_situacao === 'AGUARDANDO_PAGAMENTO' &&
                  <React.Fragment>
                    <Grid item md={2}>
                      <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => this.salvar(false)}>Salvar</Button>
                    </Grid>
                    <Grid item md={2}>
                      <ColorDarkGreenButton fullWidth variant="contained" className="btn_salvar" size="small" onClick={e => this.salvar(true)}>Salvar e Baixar</ColorDarkGreenButton>
                    </Grid>
                  </React.Fragment>
                }
              </Grid>
            </React.Fragment>
          }
        </Main>
        <Nav />
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
        <ModalExibeBoleto 
          open={this.state.openModalExibeBoleto}
          dados={this.state.login}
          handleClose={e => this.handleModalExibeBoleto()} 
          boletoSelecionado={this.state.boletoSelecionado}
          copyLink={e => this.copyLink(e)} 
        />
        <Footer history={this.props.history} />
      </div>
    )
  }
}